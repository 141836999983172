*{
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');


.two-col{display: flex;flex-direction: row;align-items: stretch;width: 100%;height: 100vh;}
.two-col .col-left{padding:50px 0 166px;}
.two-col .col-left,.two-col .col-right{width: 50%;}
.two-col .col-right .col-right-imgae{width: 100%;height: 100%;}
.two-col .col-right .col-right-imgae img{width: 100% !important;height: 100% !important;object-fit: cover;}
.two-col .col-left h1{text-align: center;font-weight: 400;color: #bfa682;font-size: 80px;line-height: 96px;font-family: Playfair Display,serif;width: 100%;}
.col-left-cover{height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;margin: 0 auto;padding: 50px 0 50px;}


@media screen and (max-width:1900px) {
  .two-col .col-left h1{font-size: 60px;line-height: 70px;}
}

@media screen and (max-width:1279px) {
  .two-col .col-left h1{font-size: 46px;line-height: 60px;}
}

@media screen and (max-width:1023px) {
  .two-col .col-left h1{font-size: 37px;line-height: 43px;}
}

@media screen and (max-width:767px) {
  .two-col{flex-direction: column-reverse;height: auto;}
  .two-col .col-left, .two-col .col-right{width: 100%;}
  .two-col .col-left{padding:50px 0;}
  .two-col .col-right .col-right-imgae{height: 248px;}
  .col-left-cover{padding: 0px;}
  .two-col .col-left{padding: 30px 20px;}
}